export default {
    props: {
        field: {
            type: Object,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        form: {
            type: Object,
            required: true
        }
    }
}